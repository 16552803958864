import React from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import { graphql, Link } from 'gatsby'
import { Box, Button, Container, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import FormSection from '../components/page-parts/form-section';
import ArrowIconDark from '../svg/arrowRight.svg'

const useStyles = makeStyles((theme) => ({

  wrapper: {
    padding: '4rem 0',
  },

  title: {
    fontSize: '1.5rem',
  },

  subtitle: {
    fontSize: '1.5rem',
    fontWeight: '600',
    marginBottom: '1rem',
  },

  techsList: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  techItem: {
    color: 'white',
    backgroundColor: '#7b78f2',
    padding: '5px 10px',
    borderRadius: '5px',
    margin: '0 10px 10px 0',
    fontSize: '1.2rem',
    fontWeight: 500,
  },

  link: {
    color: '#7b78f2',
    fontSize: '1.2rem',
    textDecoration: 'none',
    fontWeight: 500,
  },

  flexBox: {
    display: 'flex',
    flexDirection: 'column-reverse',
  },

  backLink: {
    fontSize: '1.5rem',
    textDecoration: 'none',
  },

  '@media (min-width: 1024px)': {

    flexBox: {
      flexDirection: 'row',

      '& > :first-child': {
        flexBasis: '70%',
        paddingRight: '3rem',
      },
      '& > :last-child': {
        flexBasis: '30%',
      },
    },

    smallImageBox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },

    title: {
      fontSize: '2.5rem',
    },
  },
}))

const Project = ({ data }) => {

  const classes = useStyles()

  const image = getImage(data.project.largeimage.localFile.childImageSharp)
  const smallImage = getImage(data.project.portfolioPreview.localFile.childImageSharp)

  const pageDescription = data.project.description.description

  // const keywords = 'создание сайтов, дизайн сайтов, веб дизайн, разработка сайтов'

  return (
    <Layout fixedHeader={false}>
      <Seo title={`Проект: ${data.project.name}`} description={pageDescription} />
      <Box component="section" className={classes.wrapper}>
        <Container maxWidth="xl">
          <Box className={classes.content}>
            <Box className={classes.flexBox}>
              <Box className={classes.flexItem1}>
                <Box className={classes.titleBox} mb={{xs: 4}}>
                  <Typography variant="h1" className={`${classes.title} title`}>{data.project.name}</Typography>
                </Box>
                <Box className={classes.descBox} mb={4}>
                  <p>{data.project.description.description}</p>
                </Box>
                <Box className={classes.techsBox} mb={4}>
                  <p className={classes.subtitle}>Технологии, которые мы использовали:</p>
                  <ul className={classes.techsList}>
                    {
                      data.project.techs.map(tech => (
                        <li
                          key={`${data.project.slug}-tech-${tech}`}
                          className={classes.techItem}
                        >
                          {tech}
                        </li>
                      ))
                    }
                  </ul>
                </Box>
                <Box className={classes.linkBox} mb={4}>
                  <p className={classes.subtitle}>Ссылка на сайт:</p>
                  <a className={classes.link} href={data.project.link} rel="noopener noreferrer" target="_blank">{data.project.link}</a>
                </Box>
              </Box>
              <Box className={classes.flexItem2} mb={{xs: 4, md: 0}}>
                <Box className={classes.smallImageBox}>
                  <GatsbyImage className={classes.smallImage} image={smallImage} alt={data.project.name}/>
                </Box>
              </Box>
            </Box>
            <Box mt={4} className={classes.imageBox}>
              <GatsbyImage image={image} alt={data.project.name}/>
            </Box>
          </Box>
          <Box className={classes.backToPortfolio} mt={4}>
            <Link className={classes.backLink} to="/portfolio" >
              <Button className={classes.moreBtn} variant="contained" color="primary" endIcon={<ArrowIconDark />} size="large">Вернуться к списку проектов</Button>
            </Link>
          </Box>
        </Container>
        <Box mt={4}>
          <FormSection />
        </Box>
      </Box>
    </Layout>
  )
}

export default Project

export const pageQuery = graphql`
  query($slug: String!) {
    project: contentfulProject(slug: {eq: $slug}) {
      slug
      name
      link
      description {
        description
      }
      techs
      largeimage {
        localFile {
          childImageSharp {
            gatsbyImageData (
              quality: 80
              placeholder: BLURRED
            )
          }
        }
      }
      portfolioPreview {
        localFile {
          childImageSharp {
            gatsbyImageData (
              quality: 50
              placeholder: BLURRED
            )
          }
        }
      }
    }
  }
`
